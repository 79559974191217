<template>
    <div
        class="
            container
            mx-auto
            desktop:max-w-[1440px]
            flex
            flex-col
            flex-1
            py-6
            tablet:py-16
            app-content-px
        "
    >
        <slot />
    </div>
</template>
